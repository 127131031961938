<template>
  <v-container id="a" class="flex w-full">
    <div class="mx-auto flex flex-col py-20">
      <div class="font-bold text-white text-center" style="font-size: 30px">
        BHVT Contract Address: <br />0x57e0741a2DB1839bb5EdF91D2523e45880132f6f
      </div>
      <div class="mt-16 font-bold text-white text-center" style="font-size: 30px">
        Check out BHVT’s multi-sig treasury address:
        <br />0xD1A58A090629a995fA6388813a164f3203f84717
      </div>
      <button class="buy-button px-16 mt-10 mx-auto">Treasury</button>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.buy-button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  background: rgb(0, 87, 255);
  background: linear-gradient(197deg, rgba(0, 87, 255, 1) 0%, rgba(58, 208, 38, 1) 100%);
  color: white;
  font-weight: bold;
}
</style>
