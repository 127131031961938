<template>
  <div
    id="f"
    class="background-image-container"
    :style="{
      'background-image':
        'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(' +
        require('../../assets/bg-2.png') +
        ')',
    }"
  >
    <v-container class="mx-auto flex">
      <div class="flex flex-col text-white flex-grow mx-auto py-20">
        <div class="mb-10" style="font-size: 34px">
          <strong>The Future of Investing</strong>
        </div>
        <v-row justify="between">
          <v-col cols="12" style="font-size: 16px" md="7">
            <div>
              When community-focus and innovative business minds come together to bring a
              brighter tomorrow
            </div>
            <div class="mt-4">
              We understand the need within DeFi for innovation, passive income, and most
              importantly a community! BHVT builds an ecosystem that derives its value
              from the community it retains while passively rewarding users who hold $BHVT
              long term.
            </div>
            <div class="mt-4">
              The Future of Investing When community-focus and innovative business minds
              come together to bring a brighter tomorrow We understand the need within
              DeFi for innovation, passive income, and most importantly a community! BHVT
              builds an ecosystem that derives its value from the community it retains
              while passively rewarding users who hold $BHVT long term. The BHVT
              strategies have been tried, tested, and used daily by our DeFi experts. Our
              mission is to offer $BHVT holders the same level of service without any
              added extra steps. You buy, you hold, you earn. Let us do the hard work for
              you! At genesis, we will start with a small treasury; however, as we grow,
              so will our treasury. As our treasury develops, it will also increase our
              buying power, ability to create more unique features, and generate more
              rewards for long-term $BHVT holders!
            </div>
            <div class="mt-4">
              Our goal at BHVT is to make you the digital bank. Once our contract has
              launched: we will not have a central entity, no human intervention, and no
              overheads.
            </div>
            <button
              @click="() => $router.push('/whitepaper')"
              class="buy-button px-12 mt-12"
            >
              Read Whitepaper
            </button>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" md="4">
            <img style="border-radius: 10px" :src="require('../../assets/invest.png')" />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.buy-button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  background: rgb(0, 87, 255);
  background: linear-gradient(197deg, rgba(0, 87, 255, 1) 0%, rgba(58, 208, 38, 1) 100%);
  color: white;
  font-weight: bold;
}
</style>
