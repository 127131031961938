<template>
  <div id="t">
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      style="position: relative; background-color: black"
    >
      <video autoplay muted loop id="worldVideo">
        <source :src="require('../../assets/world.mp4')" type="video/mp4" />
      </video>
      <div
        class="flex"
        style="
          position: absolute;
          top: 0;
          background-color: #00000088;
          width: 100%;
          height: 100%;
          z-index: 10;
        "
      >
        <v-container class="my-auto mx-auto">
          <div
            style="padding: 0 10px; color: white; font-weight: bold; text-align: center"
          >
            <div style="font-size: 30px">Why BHVT?</div>
            <div style="color: white" class="mt-4">
              BHVT aims to become a leader in the Defi sector by paving the way for the
              next generation of reflection tokens. By analysing and constantly improving
              the current version, BHVT has seen a gap within the market where treasury
              funds can be used to benefit the users more than just a flat percentage of
              all transactions.
            </div>
            <v-row style="width: 100%" no-gutters>
              <v-col style="padding: 10px" cols="12" md="4" sm="6">
                <div class="card-back">
                  <div style="font-size: 20px">Providing Liquidity</div>
                  <div class="mt-2" style="font-size: 15px">
                    Providing Liquidity By leveraging within the BHVT treasury to provide
                    liquidity inside reputable and secure pools, investors can have
                    confidence in knowing that the fair market valuation of the BHVT token
                    holds an inherent bias to the upside. By utilizing our strategy across
                    multiple pools offering up to 50% APR while current traditional banks
                    average less than 2%, the BHVT advantage becomes clear.
                  </div>
                </div>
              </v-col>
              <v-col style="padding: 10px" cols="12" md="4" sm="6">
                <div class="card-back">
                  <div style="font-size: 20px">NFT Acquisition</div>
                  <div class="mt-2" style="font-size: 15px">
                    The recent popularity and financial incentives within non-fungible
                    tokens (NFTs) provide BHVT with another opportunity to generate
                    revenue for investors. By utilizing the skills of our strategists,
                    BHVT will leverage an appropriate portion of treasury assets to
                    acquire and trade these digital assets for profit.
                  </div>
                </div>
              </v-col>
              <v-col style="padding: 10px" cols="12" md="4" sm="6">
                <div class="card-back">
                  <div style="font-size: 20px">Loaning Protocols</div>
                  <div class="mt-2" style="font-size: 15px">
                    The decentralized finance sector of cryptocurrency has seen massive
                    growth and the adoption of lending and borrowing protocols. Utilizing
                    a portion of the BHVT treasury to lend and reap the benefits of these
                    services is yet another way the BHVT platform provides value to
                    investors and ensures sustainable and ever-growing returns.
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </div>
    <div v-else>
      <div style="position: relative; background-color: black">
        <video autoplay muted loop id="worldVideo">
          <source :src="require('../../assets/world.mp4')" type="video/mp4" />
        </video>
        <div
          class="flex"
          style="
            position: absolute;
            top: 0;
            background-color: #00000088;
            width: 100%;
            height: 100%;
            z-index: 10;
          "
        >
          <v-container class="my-auto mx-auto">
            <div
              style="padding: 0 10px; color: white; font-weight: bold; text-align: center"
            >
              <div style="font-size: 30px">Why BHVT?</div>
              <div style="color: white" class="mt-4">
                BHVT aims to become a leader in the Defi sector by paving the way for the
                next generation of reflection tokens. By analysing and constantly
                improving the current version, BHVT has seen a gap within the market where
                treasury funds can be used to benefit the users more than just a flat
                percentage of all transactions.
              </div>
            </div>
          </v-container>
        </div>
      </div>
      <div style="padding: 0 10px; color: white; font-weight: bold; text-align: center">
        <v-row style="width: 100%" no-gutters>
          <v-col style="padding: 10px" cols="12" md="4" sm="6">
            <div class="card-back">
              <div style="font-size: 20px">Providing Liquidity</div>
              <div class="mt-2" style="font-size: 15px">
                Providing Liquidity By leveraging within the BHVT treasury to provide
                liquidity inside reputable and secure pools, investors can have confidence
                in knowing that the fair market valuation of the BHVT token holds an
                inherent bias to the upside. By utilizing our strategy across multiple
                pools offering up to 50% APR while current traditional banks average less
                than 2%, the BHVT advantage becomes clear.
              </div>
            </div>
          </v-col>
          <v-col style="padding: 10px" cols="12" md="4" sm="6">
            <div class="card-back">
              <div style="font-size: 20px">NFT Acquisition</div>
              <div class="mt-2" style="font-size: 15px">
                The recent popularity and financial incentives within non-fungible tokens
                (NFTs) provide BHVT with another opportunity to generate revenue for
                investors. By utilizing the skills of our strategists, BHVT will leverage
                an appropriate portion of treasury assets to acquire and trade these
                digital assets for profit.
              </div>
            </div>
          </v-col>
          <v-col style="padding: 10px" cols="12" md="4" sm="6">
            <div class="card-back">
              <div style="font-size: 20px">Loaning Protocols</div>
              <div class="mt-2" style="font-size: 15px">
                The decentralized finance sector of cryptocurrency has seen massive growth
                and the adoption of lending and borrowing protocols. Utilizing a portion
                of the BHVT treasury to lend and reap the benefits of these services is
                yet another way the BHVT platform provides value to investors and ensures
                sustainable and ever-growing returns.
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#worldVideo {
  width: 100%;
}
.card-back {
  width: 100%;
  padding: 20px 30px;

  margin-top: 50px;
  background-image: linear-gradient(205deg, #1749fd9e 10.27%, #01ff069c 112.2%);
  background-color: transparent;
  border-radius: 20px;
}
</style>
