<template>
  <div
    id="t"
    class="background-image-container pb-40"
    :style="{
      'background-image':
        'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(' +
        require('../../assets/header.e68553f42dafea2e4544.jpg') +
        ')',
    }"
  >
    <v-container>
      <v-row justify="center">
        <v-col md="6" lg="5" cols="12">
          <div class="pt-40">
            <div
              style="color: white; font-weight: bold; font-size: 50px; line-height: 1.2"
            >
              Buy, Hold, and Earn with BHVT Defi Community
            </div>
            <div class="mt-4">
              <div class="flex my-2" v-for="(item, index) in logoList" :key="index">
                <img :src="item.image" />
                <div class="ml-2 my-auto text-white font-bold">{{ item.text }}</div>
              </div>
            </div>

            <button class="my-2 mx-auto px-8 buy-button" @click="onBuyClick">
              Buy $BHVT
            </button>

            <div class="flex mt-4">
              <div class="mt-auto text-white font-bold">Smart Contract Audit by</div>
              <img
                class="ml-3"
                style="width: 200px"
                :src="require('../../assets/solid-proof.png')"
              />
            </div>
          </div>
        </v-col>
        <v-col lg="4" md="6" sm="7" cols="12"
          ><div
            class="mt-40 flex flex-col"
            style="overflow: hidden; border-radius: 10px; background-color: white"
          >
            <div class="card-header">
              BHVT<br />
              AGGREGATOR
            </div>
            <img
              style="width: 150px"
              class="mx-auto my-12"
              :src="require('../../assets/black-text.png')"
            />
            <div style="font-size: 18px" class="text-center mx-3">
              We have made this to help our community observe, learn and take part in
              earning rewards on their cryptos!
            </div>
            <button class="my-8 px-4 mx-auto buy-button">
              Visit BHVT Defi Aggregator
            </button>
            <div class="flex flex-wrap justify-center mx-4 mt-4 mb-8">
              <div
                class="flex my-3"
                style="width: 25%"
                v-for="(item, index) in iconList"
                :key="index"
              >
                <img
                  style="width: 32px; object-fit: contain"
                  class="mx-auto"
                  :src="item"
                />
              </div>
            </div></div
        ></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconList: [
        require("../../assets/eth.png"),
        require("../../assets/bsc-removebg-preview-ptowfmtx6epxrol0dlw3k8lb9b5tsmxdy72ph6y0s4.png"),
        require("../../assets/fantom-ftm-logo-ptowg5moz3fo7vtpbu0my3uj50l62l00os4f2q65bo.png"),
        require("../../assets/pstweatifgo8tmub5atc-ptowgofgrs5eo32ea256bz3r0q0icj2nfd64o9e9v8.webp"),
        require("../../assets/Avalanche_logo_without_text-ptowh6aedmtusocgdrv35clib1khes1jttkcsinsl0.png"),
        require("../../assets/velas-vlx-logo-ptowhp366bjl8vl5bzzmj7uq6qztoq46kem2e1vx4k.png"),
        require("../../assets/logo-removebg-preview-ptowiapgjid6nwpqtrc1mkebum19lri0bdm8fezv5g.png"),
        require("../../assets/aurora-aoa-logo-ptowiot1e0whi259jffg5yu8re3rt81zdbeimkeyk4.png"),
      ],
      logoList: [
        {
          image: require("../../assets/award.svg"),
          text: "The first token with a treasury that works for you!",
        },
        {
          image: require("../../assets/guard.svg"),
          text: "A simple but powerful tool for Defi exposure",
        },
        {
          image: require("../../assets/rocket.svg"),
          text: "Earn passive income",
        },
        {
          image: require("../../assets/static.svg"),
          text: "Multichain expansion with endless opportunity",
        },
      ],
    };
  },
  methods: {
    onBuyClick() {
      console.log(1)
      window.location.href = "https://chainbitvortex.com";
    },
  },
};
</script>

<style scoped>
.background-image-container {
  height: auto;
  width: 100%;
  border-radius: 0px 0 100px 100px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-header {
  border-radius: 0 0 60px 60px;
  line-height: 1.2;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  padding: 10px;
  background: rgb(0, 87, 255);
  background: linear-gradient(197deg, rgba(0, 87, 255, 1) 0%, rgba(58, 208, 38, 1) 100%);
  color: white;
}
.buy-button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  background: rgb(0, 87, 255);
  background: linear-gradient(197deg, rgba(0, 87, 255, 1) 0%, rgba(58, 208, 38, 1) 100%);
  color: white;
  font-weight: bold;
}
</style>
